<template>
  <restaurant-profile-component />
</template>

<script>
import RestaurantProfileComponent from "@/components/restaurants-leisures/RestaurantProfileComponent";

export default {
  name: "RestaurantProfile",
  title: "Perfil del Restaurant | Baja California Health Tourism",
  components: { RestaurantProfileComponent },
};
</script>

<style scoped></style>
